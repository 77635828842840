import React from 'react';
import Img from 'gatsby-image';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Container, Grid } from '@material-ui/core';
import { ParallaxBanner } from 'react-scroll-parallax';
import FlashIcon from '@material-ui/icons/FlashOn';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import SearchIcon from '@material-ui/icons/Search';
import CloudIcon from '@material-ui/icons/Cloud';
import FeatureSectionLight from '../components/featureSectionLight';
import SubLayout from '../layouts/subLayout';
import ReactIcon from '../../assets/svg/react-original.svg';
import FlutterIcon from '../../assets/svg/flutter-original.svg';
import SwiftIcon from '../../assets/svg/swift-original.svg';
import AllServices from '../components/allServices';
import SEO from '../components/seo';

const AppsPage = () => {
  const data = useStaticQuery(graphql`
        query AppsImages {
            mobilne: file(
                relativePath: {
                    eq: "aplikacje-mobilne-iqcode.png"
                }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            mobilneMain: file(
                relativePath: {
                    eq: "aplikacje-mobilne-iqcode-iphone12.png"
                }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            kompleksowam: file(
                relativePath: {
                    eq: "kompleksowa-usluga.png"
                }
            ) {
                id
                childImageSharp {
                    fluid(quality: 95, traceSVG: { threshold: 22 }) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
  return (
    <SubLayout
      title={(
        <>
          <span>Mobile</span>
          {' '}
          Development
        </>
)}
      subtitle="Agile development of performant mobile Apps"
      img="/img/apps-mobile-bg.jpg"
    >
      <SEO title="Mobile development - iqcode Software House" description="We design, build and ship modern mobile applications for Android and iOS using React Native and Flutter." />
      <div style={{ boxShadow: '3px 3px 30px #00000070' }}>
        <FeatureSectionLight
          title={(
            <>
              Mobile
              {' '}
              <span>Apps</span>
              {' '}
              for Android and iOS
            </>
                      )}
          img={<div style={{ padding: '0 14%' }}><Img alt="modern mobile apps" fluid={data.mobilneMain.childImageSharp.fluid} /></div>}
          technologies={[
            { icon: <ReactIcon />, name: 'React Native' },
            { icon: <FlutterIcon />, name: 'Flutter' },
          ]}
          desc={(
            <>
              <p>
                The mobile app market has been developing quite rapidly.
                Due to widespread usage of hybrid technologies such as
                React Native or Flutter, the entry barrier to this kind of a
                digital product has never been lower, resulting
                in an increasing number of small and medium businesses
                introducing their own mobile apps.
              </p>
              <p>
                We design, develop and ship mobile applications for Android and iOS.
                What makes our project stand out is excellent user experience achieved
                by UI responsiveness and its intuitive layout. In conjunction with our
                custom-developed server-side applications, we deliver complete digital products.
              </p>
            </>
                      )}
        />
      </div>
      <div className="section" id="aplikacje-mobilne">
        <ParallaxBanner
          className="phone-showcase parallax-container bg-dark overlay-holder"
          style={{ height: 'auto', backgroundColor: '#b921cb4d' }}
          layers={[{
            image: '/img/p3.jpg',
            amount: 0.4,
            props: { style: { zIndex: -1 } },
          }]}
        >
          <div className="parallax" />
          <Container>
            <div className="row">
              <div className="col m12 l4">
                <div className="phone-block">
                  <FlashIcon />
                  <h4>Blazingly Fast</h4>
                  <p>
                    Native technologies in conjunction
                    with properly optimized source offer
                    make the UI feel snappy.
                  </p>
                </div>
                <div className="phone-block">
                  <FingerprintIcon />
                  <h4>Unique UX</h4>
                  <p>
                    Our out-of-the-box solutions allow for
                    creating truly unique experiences.
                  </p>
                </div>
              </div>
              <div className="col m12 l4">
                <Img fluid={data.mobilne.childImageSharp.fluid} alt="cechy aplikacji mobilnych" />
              </div>
              <div className="col m12 l4">
                <div className="phone-block">
                  <SearchIcon />
                  <h4>App Store Optimization</h4>
                  <p>
                    We optimize the developed applications
                    so as to make it easier to find them in app stores.
                  </p>
                </div>
                <div className="phone-block">
                  <CloudIcon />
                  <h4>Custom Backend</h4>
                  <p>
                    Our custom-developed server-side applications
                    ensure flawless integration.
                  </p>
                </div>
              </div>
            </div>
          </Container>
          <div className="diagonal-overlay-color" />
        </ParallaxBanner>
        <div className="technologies bg-dark outline-fix z-depth-3">
          <Container>
            <h3>
              <span>Technologies</span>
              {' '}
              that we use
            </h3>

            <Grid container>
              <Grid className="tech-cont" item xs={12} sm={12} md={4}>
                <ReactIcon className="tech-icon" />
                <p>React Native</p>
              </Grid>
              <Grid className="tech-cont" item xs={12} sm={12} md={4}>
                <FlutterIcon className="tech-icon" />
                <p>Flutter</p>
              </Grid>
              <Grid className="tech-cont" item xs={12} sm={12} md={4}>
                <SwiftIcon className="tech-icon" />
                <p>Swift</p>
              </Grid>
            </Grid>
            <Link className="btn btn-alt" to="/technologie">Lear more</Link>
          </Container>
        </div>

        <FeatureSectionLight
          title={(
            <>
              <span>All-rounded </span>
              Mobile Development
            </>
                  )}
          mb0
          img={(
            <div style={{ padding: '10%' }}>
              <Img
                alt="complex mobile development"
                fluid={data.kompleksowam.childImageSharp.fluid}
                imgStyle={{ width: 'auto' }}
              />
            </div>
                  )}
          desc={(
            <>
              <p>
                Virtually all mobile apps require and appropriate
                {' '}
                <Link to="/backend-development/">server-side application</Link>
                {' '}
                in order to deliver all of their functionalities.
              </p>
              <p>
                We deliver complex IT projects starting at the requirements engineering stage, through development works following
                {' '}
                <strong>agile</strong>
                {' '}
                principles and project consulting regarding the app's public start finishing off with rock-solid tech support, data-driven analytics and iterative evolution of the digital product.
              </p>
              <p>
                Our wide variety of mobile, web and server-side technologies allows us to deliver complex IT projects cross-cutting numerous software engineering areas devoid of technical debt.
              </p>
            </>
                  )}
          link="Porozmawiajmy"
          to="/kontakt"
        />

        <AllServices />
      </div>
    </SubLayout>
  );
};

export default AppsPage;
